export default {
    dateISOString(d) { 
        if(!d) { 
            d = new Date()
        }

        var y = d.getFullYear().toString()
        var m = (d.getMonth()+1).toString()
        var d = d.getDate().toString()

        return y + '-' + (m.length > 1 ? m : '0' + m) + '-' + (d.length > 1 ? d : '0' + d)
    },  
}